export default class Authorization {

    constructor() {
        // Set up any necessary variables and properties
        this.auth = btoa("cconnect:cconnect");
        this.token = localStorage.getItem('token');
       
        this.session = null;
    }

    async login(username, password, weekEnd) {
        // Make a request to the server to log in with the given username and password
        // and store the resulting token in the class instance
        let friday = this.getFridayOfWeek(weekEnd);
        return await fetch('https://uni-api-01.ozisp.com.au/APIV8Login', {
            method: 'POST',
            cors: true,
            body: JSON.stringify({user_id: username, password: password, process_date: friday ,access_type: "TIMESHEET"}),
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Basic ${this.auth}`
            }
        }).then(response => {
            return response.json()
        }).then(data => {
            if(data){
                if (data.result.result_flag === "Y") {
                    this.token = data.session.token;
                    this.session = data;
                    localStorage.setItem('auth', this.auth);
                    localStorage.setItem('tokenValid', true)
                    return data;
                } else {
                    return data;
                }
            }
            

        });
    }

    getFridayOfWeek = (weekEnd) => {
        const date = new Date(weekEnd);
        // console.log(date)
        const formattedDate = `${date
            .getDate()
            .toString()
            .padStart(2, "0")}/${ (date.getMonth() + 1)
            .toString()
            .padStart(2, "0")}/${date
            .getFullYear()
            .toString()}`;
          
            localStorage.setItem('weekEnd', formattedDate);
        return formattedDate;
    }
    async authorize() {
        // Make a request to the server to authorize the stored token and return a
        // boolean indicating whether the token is valid or not
        
        // if(!this.token){
        //     console.log('false')
        //     return false;
        // }
        


        try {
            return  fetch('https://uni-api-01.ozisp.com.au/APITSTOT', {
            method: 'POST',
                cors: true,
                body: JSON.stringify({
                    WebUserId: JSON.parse(localStorage.getItem('session')).LoginData.WebUserId,
                    WeekendDate: localStorage.getItem('weekEnd'),
                    token: this.token
                }),
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Basic ${this.auth}`
                }
        }).then(response => {
           
            return response.json()
        }).then(data => {
            console.log(data)
            if(data){
                if (data.result.result_flag === "Y") {
                    localStorage.setItem('tokenValid', true)
              
                return true;
                } else {
                    localStorage.setItem('tokenValid', false)
                return false;
                }
            }
            

        });
            // const response = await fetch('https://uni-api-01.ozisp.com.au/APITSTOT', {
            //     method: 'POST',
            //     cors: true,
            //     body: JSON.stringify({
            //         WebUserId: JSON.parse(localStorage.getItem('session')).LoginData.WebUserId,
            //         WeekendDate: localStorage.getItem('weekEnd'),
            //         token: this.token
            //     }),
            //     headers: {
            //         'Content-Type': 'application/json',
            //         "Authorization": `Basic ${this.auth}`
            //     }
            // });

            
            // // const data = await response.json()
            // console.log(response);

            // if (response.ok) {
            //     localStorage.setItem('tokenValid', true)
              
            //     return true;

            // } else {
            //     localStorage.setItem('tokenValid', false)
            //     return false;
            // }

        } catch (err) {
            // console.error(err);
            return false;
        }


    }

    

}