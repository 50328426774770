import React from 'react'

const LoaderCircle = () => {
  return (
    <div className="flex justify-center items-center ">
        <div
            className="border-4 border-gray-200 border-t-uni-green-dark border-opacity-50 rounded-full w-8 h-8 animate-spin"></div>
    </div>
  )
}

export default LoaderCircle