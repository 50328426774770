import {React, useState, useEffect} from 'react'
import JobForm from '../../components/form/JobForm';
import JobData from '../../components/jobdata/JobData';
import UniApi from "../../classes/UniApi.js";

import Notification from '../../components/notification/Notification';
import LoaderCircle from '../../components/loadercircle/LoaderCircle';

// function getFridayOfWeek() {
//     const today = new Date();
//     const friday = new Date(today.getTime() + ((5 - today.getDay()) % 7) * 86400000); // 86400000 = number of milliseconds in a day // Format the date as a string in the desired format (dd-mm-yyyy)
//     const formattedFriday = `${friday
//         .getDate()
//         .toString()
//         .padStart(2, "0")}/${ (friday.getMonth() + 1)
//         .toString()
//         .padStart(2, "0")}/${friday
//         .getFullYear()
//         .toString()}`;
//     return formattedFriday;
// }

const JobContent = (props) => {
    
    const [options,
        setOptions] = useState(null);
    const [addJob,
        setAddJob] = useState(false);
    const [jobList, setJobList] = useState([]);
    
    const [notify, setNotify] = useState(false);
    const [notifyMsg, setNotifyMsg] = useState({message:"No Message", type:"success"})
    const [editMode, setEditMode] = useState({editMode: false, index: null});
    
    const [editData, setEditData] = useState(null);

    const WebUserId = localStorage.getItem('session')
    const [loader,setLoader] = useState(false);

    const [submissionData, setSubmissionData] = useState({});
    const [submissionModal, setSubmissionModal] = useState(false);
    
    const [todaysDate, setTodaysDate] = useState('');
   

    // useEffect(() => {
    //     let authorize = new Authorization();
    //     props.auth(authorize.authorize())
    //     const interval = setInterval(() => {
    //         props.auth(authorize.authorize());
    //     }, 1000 * 60 * 5); // check auth every 5 minutes
    //     return () => clearInterval(interval);
    // }, []);


    useEffect(() => {
       
        // Reuse state held data to fill drop down fields
        if(options === null){
            async function fetchData() {
                let cc_call = new UniApi();
                
                
                const args = {
                    "token": localStorage.getItem('token'),
                    "WebUserId": JSON
                        .parse(WebUserId)
                        .LoginData
                        .WebUserId,
                        
                }
                const methods = ["APITSCODES", "APITSCOMPANY", "APITSCOMPLIST"];
    
                const stateData = {};
                await Promise.all(methods.map(async(method) => {
                    let response = await cc_call.cc_call(method, args);
                    const data = response;
                    if (data.result.result_flag === "Y") {
                        
                        stateData[method] = data;
                    } 
    
                }));
                setOptions(stateData);
            }
            if(localStorage.getItem('tokenValid')) {
                fetchData();
            }
        }

         // Get the current date in UTC
         const currentDateUTC = new Date();
         // Define the time difference between UTC and Australian Eastern Standard Time (AEST) in milliseconds
         const timeZoneOffset = 10 * 60 * 60 * 1000; // AEST is UTC+10
         // Adjust the current date to AEST
         const currentDateAEST = new Date(currentDateUTC.getTime() + timeZoneOffset);
         // Extract the date part in "YYYY-MM-DD" format
         const currentDateAESTString = currentDateAEST.toISOString().substring(0, 10);
         
         setTodaysDate(currentDateAESTString);
        
        
    }, []);

    const handleSubmit = async(formData, mode) => {
        setLoader(true);
        let cc_call = new UniApi();
        const args = {
            "token": localStorage.getItem('token'),
            "WebUserId": JSON
                .parse(WebUserId)
                .LoginData
                .WebUserId,
            "WeekendDate":  localStorage.getItem('weekEnd'),
            "webpageopt": mode,
            ...formData
        }

        const method = "APITSJOBADD";
        let response = await cc_call.cc_call(method, args);
        const data = response;
        if (data.result.result_flag === "Y" && data.error.error_code === '' || data.error.error_code === 'N' ) {
            setNotify(true);
            setNotifyMsg({message:"Job has been successfully Updated", type:"success"})
            setAddJob(!addJob);
            setLoader(false);
            return data.result.result_flag;
        } else {
          
          setNotify(true);
          setNotifyMsg({message:data.error.error_message, type:"error"})
          setLoader(false)
          return data.result.result_flag;
      
        }
       

    };

    const handleEdit = (data, mode, index) => {
       
        setEditMode({editMode: true, index: index});
        setEditData({formData: data, mode: mode});
        console.log(data);
        // handleSubmit(data, mode);
    }
    const cancelEditMode = () => {
        setEditMode({editMode:false});
        setEditData(null)
    }
    const handleDelete = (mode) => {
        cancelEditMode();
        handleSubmit("", mode);
    }
    const handleJobData = async() => {
        let cc_call = new UniApi();
        if(localStorage.getItem('token')) {

      
        const args = {
            "token": localStorage.getItem('token'),
            "WebUserId": JSON
                .parse(WebUserId)
                .LoginData
                .WebUserId,
            "WeekendDate": localStorage.getItem('weekEnd')
        }
        // APITSTOT is returning incorrectly
        const methods = ["APITSCHRG", "APITSNONCHRG","APITSJOBLIST", "APITSTOT"];
        const stateData = {};
            await Promise.all(methods.map(async(method) => {
                let response = await cc_call.cc_call(method, args);
                const data = response;
                if (data.result.result_flag === "Y") {
                    console.log('Y')
                    stateData[method] = data;
                } 

            }));
            setJobList(stateData);
        }
    }

    const handleTimeSheetSubmission = async(data)=> {
     
        setSubmissionData(data);
        setSubmissionModal(true);

        
    }
    const confirmSubmission = async() => {
        setSubmissionModal(!submissionModal);
        let cc_call = new UniApi();
        const args = submissionData;

        const method = "APITSSUBMIT";
        let response = await cc_call.cc_call(method, args);
        const data = response;
        if (data.result.result_flag === "Y" && data.error.error_code === '' || data.error.error_code === 'N' ) {
            setNotify(true);
            setNotifyMsg({message:data.result.result_message, type:"success"})
        } else {
          setNotify(true);
          setNotifyMsg({message:data.error.error_message, type:"error"})
          
        }
    
    }
    const toggleSubmissionModal = () => {
        return setSubmissionModal(!submissionModal);
    }

    useEffect(() => {
        handleJobData();
    }, [addJob])

    return (
        <div
            className=" flex flex-wrap lg:flex-nowrap w-full justify-between ">
                
                {notify &&
                    <Notification message={notifyMsg.message} type={notifyMsg.type} show={setNotify} />}
            <div className='w-[100%] lg:w-1/3 border-uni-green-dark '>
                {options
                    ? <JobForm options={options} submission={handleSubmit} edit={editData} isEditMode={editMode} cancelEditMode={cancelEditMode} loader={loader} todaysDate={todaysDate} />
                    : <LoaderCircle/>}

            </div>
            <div className="w-[100%]  px-2 overflow-x-auto">
                
                {jobList.length !== 0
                    ? <JobData data={jobList} edit={handleEdit}  editMode={editMode} delete={handleDelete}  submission={handleTimeSheetSubmission}/>
                    : <LoaderCircle/>}
            </div>
            {submissionModal ? 
            <div className='bg-uni-green-dark/50 min-w-full min-h-screen fixed top-0 left-0 '>
            <div className='absolute left-[50%] translate-x-[-50%] top-1/2 translate-y-[-50%] flex flex-col justify-center bg-white p-5 border-uni-green-dark  border-2 '>
                <p className='font-bold text-uni-green-dark'>Are you sure you want to submit your timesheet?</p>
                <div className="flex flex-row justify-around py-2">
                <button onClick={() => confirmSubmission()} className="px-5 py-2 text-white bg-uni-green-dark border-2 text-sm border-uni-green-light flex-shrink hover:bg-uni-green-mid   transition ease-in-out">
                    <div className='text-lg'>Yes</div>
                </button>
                <button onClick={() => toggleSubmissionModal()} className='px-5 py-2 text-white bg-uni-green-dark border-2 text-sm border-uni-green-light flex-shrink hover:bg-uni-green-mid transition ease-in-out'>
                    <div className="text-lg">No</div></button>
                </div>
            </div>
            </div>
            : null}
        </div>
    )
}

export default JobContent