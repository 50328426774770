import React, {useState,useEffect} from 'react';

import Authorization from '../../classes/Authorization';

const LoginForm = (props) => {

    const [username,
        setUsername] = useState('');

    const [password,
        setPassword] = useState('');
        const [weekEnd,
            setWeekEnd] = useState('');
    const [errorMessage,
        setErrorMessage] = useState('');
        const [errorMessageDate,
            setErrorMessageDate] = useState('');

    
    useEffect(() => {
        let today = new Date();
        let friday = new Date(today.getTime() + ((5 - today.getDay() + 7) % 7) * 24 * 60 * 60 * 1000);
        let formattedDate = friday.getFullYear() + "-" + ((friday.getMonth() + 1) < 10 ? "0" : "") + (friday.getMonth() + 1) + "-" + (friday.getDate() < 10 ? "0" : "") + friday.getDate();
        
        if (today.getDay() === 5) { // If today is Friday
            formattedDate = today.getFullYear() + "-" + ((today.getMonth() + 1) < 10 ? "0" : "") + (today.getMonth() + 1) + "-" + (today.getDate() < 10 ? "0" : "") + today.getDate();
        }
           
        

        setWeekEnd(formattedDate);
    
      
    }, [])
    

    const handleUsernameChange = (e) => {

        setUsername(e.target.value);

    };


    const handlePasswordChange = (e) => {
            setPassword(e.target.value);
  
    };
    const handleWeekEndChange = (e) => {
        
       
         setWeekEnd(e.target.value);
    };
    const handleSubmit = async(e) => {

        e.preventDefault();
        if(username !== '' && password !== '' && weekEnd !== ''){
            const auth = new Authorization();
            const response = await auth.login(username, password, weekEnd);
            
            if(response.result.result_flag === 'N'){
                setErrorMessage(response.error.error_message);
            }
            if(response.result.result_flag === 'Y'){
                props.session(response)
            }
        } else {
            setErrorMessage('Please input Username, Password and Weekend Date');
         
        }

        
    };
    const input_classname = 'my-1 p-1 border-4 border-uni-green-light w-full text-uni-green-dark';

    return (
        <div className='flex min-w-[200px] max-w-[300px]'>
        <form className='flex flex-col  ' onSubmit={handleSubmit}>

           

            <input
                className={input_classname}
                type="text"
                value={username}
                placeholder='Username'
                onChange={handleUsernameChange}/>

            <input
                type="password"
                className={input_classname}
                value={password}
                placeholder='Password'
                onChange={handlePasswordChange}/>
 {errorMessage && <p className='p-1 bg-red-700 text-white'>{errorMessage}</p>}
                <input
                type="date"
                className={input_classname}
                value={weekEnd}
                onChange={handleWeekEndChange}/>
 {errorMessageDate && <p className='p-1 bg-red-700 text-white'>{errorMessageDate}</p>}
            <button
                className='my-2 p-1 text-white bg-uni-green-dark border-4 border-uni-green-light flex-shrink hover:bg-uni-green-mid transition ease-in-out'
                type="submit">Login</button>

        </form>
        </div>

    );

};

export default LoginForm;
