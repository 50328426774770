import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form';
import LoaderCircle from '../../components/loadercircle/LoaderCircle';
import Delete from '../../components/icons/Delete'
import Edit from '../../components/icons/Edit'
const TableHeader = () => {
    const table_header = "bg-uni-green-mid text-center border-2 border-uni-green-light text-white min-w-[100px] ";

    return (
        <thead>
            <tr>
                <th className={table_header }>Edit</th>
                <th className={table_header }>Date</th>
                <th className={table_header}>Company</th>
                <th className={table_header}>Customer</th>
                <th className={table_header}>Job No.</th>
                <th className={table_header}>Quote No.</th>
                <th className={table_header}>Job Desc.</th>
                <th className={table_header}>Charge Method</th>
                <th className={table_header}>Total</th>
            </tr>
        </thead>
    );
}

const TableDivider = ({date,total}) => {
    const table_data = " text-center bg-uni-green-dark text-white font-bold";

    return (
        <tr className='bg-uni-green-dark'>
            <td></td>
            <td ></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className={table_data}>Total Time</td>
            <td className={table_data}>{total}</td>
            
        </tr>
    )
}

const TableRow = (props) => {
    
    const table_data = " text-center border-2 border-uni-green-light";

    return (
        <>
        <tr
            className={props.index === props.editMode.index
            ? "bg-uni-green-dark text-white"
            : parseInt(props.data.JobDay) % 2
                ? "bg-white"
                : "bg-neutral-200"}>
            <td className={table_data + " bg-uni-green-dark"}>
                <div className='flex justify-center'>
                    <button
                        onClick={() => props.edit(props.data, "MOD_" + props.data.LineCount, props.index)}
                        className=' px-2 py-1 text-white bg-uni-green-dark  border-uni-green-light flex-shrink hover:bg-uni-green-mid transition ease-in-out'><Edit/>
                    </button>
                    {props.index === props.editMode.index && <button
                        onClick={() => props.delete("DEL_" + props.data.LineCount)}
                        className='px-2 py-1 text-white bg-uni-green-dark  border-uni-green-light flex-shrink hover:bg-uni-green-mid transition ease-in-out'><Delete/></button>
}
                </div>
            </td>
            <td className={table_data}>{props.data.JobDay}/{props.data.JobMonth}/{props.data.JobYear}</td>
            <td className={table_data}>{props.data.JobComp}</td>
            <td className={table_data}>{props.data.JobCust}</td>
            <td className={table_data}>{props.data.JobNum === 'NOPRINT'
                    ? '-'
                    : props.data.JobNum}</td>
            <td className={table_data}>{props.data.JobQuoteNum === 'NOPRINT'
                    ? '-'
                    : props.data.JobQuoteNum}</td>
            <td className={table_data + " whitespace-pre text-wrap"}>{decodeURIComponent(props.data.JobInfo)}</td>
            <td className={table_data}>{props.data.JobDesc}</td>

            <td className={table_data}>{props.data.JobTime}</td>
        </tr>
        {props.index === props.divider && <TableDivider total={props.total} date={`${props.data.JobDay}/${props.data.JobMonth}/${props.data.JobYear}`} />}
        </>
    )
}

const JobData = (props) => {
    const [jobGroup,
        setJobGroup] = useState([])
    const [totalTime,
        setTotalTime] = useState([])

    const {register, handleSubmit} = useForm();
    const table_header = "bg-uni-green-mid text-sm text-right border-2 border-uni-green-light text-white p" +
            "r-1";

    const table_data = "bg-white text-right text-sm border-2 border-uni-green-light pr-1";

   

   

    const onSubmit = data => {

        props.submission(data);

    };

    useEffect(() => {
         // Get today's date
        let weekend = localStorage.getItem('weekEnd');
        let weekendDate = weekend.split('/')
        // console.log(`${weekendDate[2]}-${weekendDate[1]}-${weekendDate[0]}`)
        const today = new Date(`${weekendDate[2]}-${weekendDate[1]}-${weekendDate[0]}`);

        // Calculate the date of Monday this week
        const monday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + 1);
        // Week starts on saturday
        const saturdayBeforeMonday = new Date(monday.getFullYear(), monday.getMonth(), monday.getDate() - 2);

         // Create an array of 5 dates, starting from Monday
        const dates = [...Array(7)].map((_, i) => {
            const date = new Date(saturdayBeforeMonday);
            date.setDate(saturdayBeforeMonday.getDate() + i);
            return `${date.getDate()}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`;
        });
   
        let jobGroups = [];
        let timeGroups = [];
        dates.forEach((date, index) => {
            let time = 0;
            let filteredGroup = props
                .data
                .APITSJOBLIST
                .TSJobList
                .filter(job => {
                    const formattedDate = `${job.JobDay}/${job.JobMonth}/${"20"+job.JobYear}`;
                    
                    return formattedDate === date && job;
                });

            filteredGroup.map(job=> {
              
                const jobTimeParts = job.JobTime.split(':');
                const hours = parseInt(jobTimeParts[0]);
                const minutes = parseInt(jobTimeParts[1]);
                const hoursToMinutes = hours * 60 + minutes;
                
                return time += hoursToMinutes;

                
                
            } )
            let formattedHours = String(Math.floor(time /60)).padStart(2,'0')
            let formattedMinutes= String(time % 60).padStart(2,'0');
            timeGroups.push(`${formattedHours}:${formattedMinutes}`);
            jobGroups.push(filteredGroup)
        });
        setJobGroup(jobGroups)
        setTotalTime(timeGroups)


       
    }, [props])

    return (
        <div className='overflow-x min-w-full'>

            <table
                className="table-auto text-sm border-collapse  min-w-full">
                <TableHeader/>

                <tbody>
                    
                    {
                    jobGroup.length > 0
                        ? jobGroup.map((group, count) =>
                            
                            group.map((job, index) => {
                               
                                return < TableRow key = {
                                    job.LineCount
                                }
                                edit = {
                                    props.edit
                                }
                                delete = {
                                    props.delete
                                }
                                editMode = {
                                    props.editMode
                                }
                                data = {
                                    job
                                }
                                index = {
                                    job.LineCount
                                } 
                                divider={group[group.length - 1].LineCount}
                                total={totalTime[count]} />
                        })
                    )
                            
                        : null
}
                </tbody>
        

                        <tbody>

                            <tr>
                                <td colSpan={7}></td>
                                <td  className={table_header}>Chargeable</td>
                                <td className={table_data}>{props.data.APITSCHRG.TSTimesChargeHours.Hours
                                        ? props.data.APITSCHRG.TSTimesChargeHours.Hours
                                        : "0:00"}</td>
                            </tr>
                            <tr>
                                <td colSpan={7}></td>
                                <td  className={table_header}>Non Chargeable</td>
                                <td className={table_data}>{props.data.APITSNONCHRG
                                        ? props.data.APITSNONCHRG.TSTimesNonChargeHours.Hours
                                        : null}</td>
                            </tr>
                            <tr>
                                <td colSpan={7}></td>
                                <td  className={table_header}>Total</td>
                                <td className={table_data}>{props.data.APITSTOT
                                        ? props.data.APITSTOT.TSTimesTotalHours.Hours
                                        : null}</td>
                            </tr>
                        </tbody>
                    </table>
                
                {props.loader
                    ? <LoaderCircle/>
                    : <div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <input
                                type='hidden'
                                value={localStorage.getItem('token')}
                                {...register('token', { required: 'Token must be valid.'}) }/>
                            <input
                                type='hidden'
                                value={JSON
                                .parse(localStorage.getItem('session'))
                                .LoginData
                                .WebUserId}
                                {...register('WebUserId', { required: 'Web User Id Required.'}) }/>
                            <input
                                type='hidden'
                                value={localStorage.getItem('weekEnd')}
                                {...register('WeekendDate', { required: 'End Date of the Working Week Required.'}) }/>
                            <input type='hidden' value="00:00" {...register('TSTIMESAfterHours') }/>

                            <button
                                className='float-right p-1 text-white bg-uni-green-dark border-4 text-sm border-uni-green-light flex-grow hover:bg-uni-green-mid transition ease-in-out'
                                type="submit">Submit Timesheet
                            </button>
                        </form>
                    </div>}

            {/* </div> */}
        </div>
    )
}

export default JobData