import React, {useState, useEffect} from "react";

const Notification = ({message, type, show}) => {
    const [isVisible,
        setIsVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(false);
            show(false);
        }, 3000);
        return () => {
            clearTimeout(timer);
        };
    }, []);
    const colors = {
        success: "bg-uni-green-mid",
        error: "bg-red-500",
        warning: "bg-yellow-500"
    };
    const notificationStyle =
     `${colors[type]} fixed top-0 left-0 w-full h-16 flex justify-center items-center z-50 transition-all duration-500 ${isVisible
        ? "opacity-100"
        : "opacity-0 pointer-events-none"} `;

    return (
        <div>
            {isVisible && (
                <div className={notificationStyle}>
                    <p className="text-white">{message}</p>
                </div>
            )}
        </div>
    );
};
export default Notification;