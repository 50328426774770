export default class UniApi {

    constructor() {
        this.token = localStorage.getItem('token');

    }

    async cc_call(method, args) {

        return await fetch(`https://uni-api-01.ozisp.com.au/${method}`, {
            method: 'POST',
            cors: true,
            body: JSON.stringify(args),
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Basic ${localStorage.getItem('auth')}`
            }
        }).then(response => {
            return response.json()
        }).then(data => {

            return data;
        });

    }

    logout(logout) {

        localStorage.clear();

    }
}