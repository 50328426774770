import {useState, useEffect} from 'react'
import './App.css';
import JobContent from './containers/jobcontent/JobContent';
import LoginForm from './components/loginForm/LoginForm';
import Authorization from './classes/Authorization';

function App() {

    const [isAuth,
        setIsAuth] = useState(false);
    useEffect(() => {
        const checkAuth = async() => {
            let auth = new Authorization();
            let authorize = await auth.authorize();


            if (authorize === true) {
                setIsAuth(true)
            } else {
                setIsAuth(false)
            }
        }
        checkAuth()
        
       
    }, []);

    
    const handleLogout = () => {
        setIsAuth(false);
        localStorage.clear();
    }

    const handleAuth = (auth) => {
        setIsAuth(auth);
    }

    const handleSession = (e) => {

        if (e.result.result_flag === 'Y') {
            setIsAuth(true);
            localStorage.setItem('token', e.session.token);
            localStorage.setItem('session', JSON.stringify(e));
        } else {
            setIsAuth(false)
        }

    }

    return (
        <div className="App ">
            <div className='p-5 flex justify-between'>
                <div className="">
                    <p className='text-uni-green-mid font-bold'>Welcome back {JSON.parse(localStorage.getItem('session'))
                            ? `${JSON
                                .parse(localStorage.getItem('session'))
                                .LoginData
                                .WebUserName}  Week End Date ${localStorage.getItem('weekEnd')}`
                               
                            : null}</p>

                </div>
                <div
                    className=" p-1 text-white bg-uni-green-dark border-2 text-sm border-uni-green-light flex-shrink hover:bg-uni-green-mid transition ease-in-out">
                    <button
                        type="button"
                        onClick={() => {
                        handleLogout()
                    }}>Logout</button>
                </div>
            </div>
            <div
                className='bg-gradient-to-r from-uni-green-dark to-white p-5  justify-center flex  min-h-screen'>
                {isAuth === true
                    ? <JobContent auth={handleAuth}/>
                    : <LoginForm session={handleSession}/>}
            </div>
        </div>
    );
}

export default App;
